import SFST_2_poster from './assets/images/SFST/SFST_2_poster.jpg';

// Function to set the caption to be the alt text as well, short name because we are going to call it alot
function x(y) {
  return Object.assign(y, { caption: y.alt });
}

// Note: Sizes attribute only required for first element, this is the sizes on the home page
// Sizes attribute is automatically calculated for lightbox
const PATSImages = [
  x({
    src: './PATS/PATS_1-%ww.%e',
    exts: ['png', 'webp'],
    widths: [420, 370, 298, 277, 223, 168, 139, 105],
    sizes: '177px', // 250px * 0.710 aspect ratio
    aspectRatio: 0.71,
    alt: 'Splash screen / logo'
  }),

  x({
    src: './PATS/PATS_2-%ww.%e',
    exts: ['png', 'webp'],
    widths: [1920, 1690, 1363, 1267, 1018, 768, 634, 480],
    aspectRatio: 1.85,
    alt: 'Three plane views shown for MRI scan on right, data loaded in module on left'
  }),

  x({
    src: './PATS/PATS_3-%ww.%e',
    exts: ['png', 'webp'],
    widths: [1920, 1690, 1363, 1267, 1018, 768, 634, 480],
    aspectRatio: 1.85,
    alt: 'Abdominal volume calculator module shown in left pane, used for calculating fat per vertebral slice'
  }),

  x({
    src: './PATS/PATS_4-%ww.%e',
    exts: ['png', 'webp'],
    widths: [1920, 1690, 1363, 1267, 1018, 768, 634, 480],
    aspectRatio: 1.85,
    alt: 'Cardiac MRI cine volume converter module shown in left pane, resulting cardiac MRI image shown on right'
  }),

  x({
    src: './PATS/PATS_5-%ww.%e',
    exts: ['png'],
    widths: [1920, 1690, 1363, 1267, 1018, 768, 634, 480],
    aspectRatio: 1.85,
    alt: 'Data properties module shown in left with info about data object'
  }),

  x({
    src: './PATS/PATS_6-%ww.%e',
    exts: ['png', 'webp'],
    widths: [1920, 1690, 1363, 1267, 1018, 768, 634, 480],
    aspectRatio: 1.85,
    alt: 'Module drop-down displayed showing the available modules for PATS'
  }),

  x({
    src: './PATS/PATS_7-%ww.%e',
    exts: ['png', 'webp'],
    widths: [1920, 1690, 1363, 1267, 1018, 768, 634, 480],
    aspectRatio: 1.85,
    alt: 'IQ to B-mode converter module shown in left pane, resulting B-mode image from algorithm shown in right'
  })
];

const SDFSAImages = [
  // Image is only 6kB at a width of 835px, no need to resize
  x({
    src: './SDFSA/SDFSA_1-%ww.%e',
    exts: ['png'],
    widths: [835],
    sizes: '346px', // 250px * 1.384 aspect ratio
    alt: 'Main screen for program with an example subject already loaded'
  }),

  x({
    src: './SDFSA/SDFSA_2-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [960, 845, 682, 634, 509, 384, 317, 240],
    aspectRatio: 1.26,
    alt: 'Configure window for subject showing umbilicis user landmark selection'
  }),

  x({
    src: './SDFSA/SDFSA_3-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [960, 845, 682, 634, 509, 384, 317, 240],
    aspectRatio: 1.26,
    alt: 'Configure window for subject showing user selected cardiac bounds'
  }),

  x({
    src: './SDFSA/SDFSA_4-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [960, 845, 682, 634, 509, 384, 317, 240],
    aspectRatio: 1.26,
    alt: 'Configure window for subject showing water image with cardiac bounds'
  }),

  x({
    src: './SDFSA/SDFSA_5-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [960, 845, 682, 634, 509, 384, 317, 240],
    aspectRatio: 1.26,
    alt: 'Configure window for subject showing user selected arm bounds'
  }),

  x({
    src: './SDFSA/SDFSA_6-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [960, 845, 682, 634, 509, 384, 317, 240],
    aspectRatio: 1.09,
    alt: 'General algorithm block diagram'
  }),

  x({
    src: './SDFSA/SDFSA_7-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [960, 845, 682, 634, 509, 384, 317, 240],
    aspectRatio: 1.44,
    alt: 'Abdominal cavity block diagram continuation'
  }),

  x({
    src: './SDFSA/SDFSA_8-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [960, 845, 682, 634, 509, 384, 317, 240],
    aspectRatio: 1.12,
    alt: 'Thoracic cavity block diagram continuation'
  })
];

const EndocardialSegmentationImages = [
  x({
    src: './EndocardialSegmentation/EndocardialSegmentation_1-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [1440, 1267, 1022, 950, 763, 576, 475, 360],
    sizes: '(max-width: 515px) calc(100vw - 80px), 417px', // 250px * 1.667 aspect ratio
    alt: 'BMES poster presented in Atlanta, 2018 describing algorithm'
  })
];

const CTLAImages = [
  x({
    src: './CTLAndroid/CTLA_1-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [384, 338, 273, 253, 204, 154, 127, 96],
    sizes: '250px', // 250px * 1.0 aspect ratio
    aspectRatio: 1.0,
    alt: 'Custody Transfer Log logo'
  }),

  x({
    src: './CTLAndroid/CTLA_2-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [540, 475, 383, 356, 286, 216, 178, 135],
    aspectRatio: 0.56,
    alt: 'Login screen'
  }),

  x({
    src: './CTLAndroid/CTLA_3-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [540, 475, 383, 356, 286, 216, 178, 135],
    aspectRatio: 0.56,
    alt: 'Reset password screen'
  }),

  x({
    src: './CTLAndroid/CTLA_4-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [540, 475, 383, 356, 286, 216, 178, 135],
    aspectRatio: 0.56,
    alt: 'Create account screen'
  }),

  x({
    src: './CTLAndroid/CTLA_5-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [540, 475, 383, 356, 286, 216, 178, 135],
    aspectRatio: 0.56,
    alt: 'Main menu/screen'
  }),

  x({
    src: './CTLAndroid/CTLA_6-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [540, 475, 383, 356, 286, 216, 178, 135],
    aspectRatio: 0.56,
    alt: 'Add child popup menu'
  }),

  x({
    src: './CTLAndroid/CTLA_7-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [540, 475, 383, 356, 286, 216, 178, 135],
    aspectRatio: 0.56,
    alt: 'Rate exchange menu after selecting child'
  }),

  x({
    src: './CTLAndroid/CTLA_8-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [540, 475, 383, 356, 286, 216, 178, 135],
    aspectRatio: 0.56,
    alt: 'Confirm event menu after rating exchange'
  }),

  x({
    src: './CTLAndroid/CTLA_9-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [540, 475, 383, 356, 286, 216, 178, 135],
    aspectRatio: 0.56,
    alt: 'View report menu from main screen'
  }),

  x({
    src: './CTLAndroid/CTLA_10-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [540, 475, 383, 356, 286, 216, 178, 135],
    aspectRatio: 0.56,
    alt: 'Filters can be applied to the report to select certain events'
  }),

  x({
    src: './CTLAndroid/CTLA_11-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [540, 475, 383, 356, 286, 216, 178, 135],
    aspectRatio: 0.56,
    alt: 'Delete children popup confirmation'
  })
];

const CTLIImages = [
  // Use image from CTLAndroid to prevent downloading the same image twice
  CTLAImages[0],

  x({
    src: './CTLiOS/CTLI_2-%ww.%e',
    exts: ['png', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'Loading screen for iPhone XR'
  }),

  x({
    src: './CTLiOS/CTLI_3-%ww.%e',
    exts: ['png', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'Login screen'
  }),

  x({
    src: './CTLiOS/CTLI_4-%ww.%e',
    exts: ['png', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'Reset password screen'
  }),

  x({
    src: './CTLiOS/CTLI_5-%ww.%e',
    exts: ['png', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'Create account screen'
  }),

  x({
    src: './CTLiOS/CTLI_6-%ww.%e',
    exts: ['png', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'Main menu/screen'
  }),

  x({
    src: './CTLiOS/CTLI_7-%ww.%e',
    exts: ['png', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'Main menu/screen with dropoff selected'
  }),

  x({
    src: './CTLiOS/CTLI_8-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'Add child popup menu'
  }),

  x({
    src: './CTLiOS/CTLI_9-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'Main screen with children selected for dropoff'
  }),

  x({
    src: './CTLiOS/CTLI_10-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'Rate exchange menu after selecting child'
  }),

  x({
    src: './CTLiOS/CTLI_11-%ww.%e',
    exts: ['png', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'Confirm event menu after rating exchange'
  }),

  x({
    src: './CTLiOS/CTLI_12-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'View report menu from main screen'
  }),

  x({
    src: './CTLiOS/CTLI_13-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'View report menu from main screen with events expanded'
  }),

  x({
    src: './CTLiOS/CTLI_14-%ww.%e',
    exts: ['png', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'Filters can be applied to the report to select certain events'
  }),

  x({
    src: './CTLiOS/CTLI_15-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'Overflow menu additional options'
  }),

  x({
    src: './CTLiOS/CTLI_16-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'Generating PDF loading screen'
  }),

  x({
    src: './CTLiOS/CTLI_17-%ww.%e',
    exts: ['png', 'webp'],
    widths: [444, 391, 315, 293, 235, 178, 147, 111],
    aspectRatio: 0.46,
    alt: 'Example PDF report generated being viewed'
  })
];

const pynrrdImages = [
  // Image is only 8kB at a width of 460px, no need to resize
  x({
    src: './pynrrd/pynrrd_1-%ww.%e',
    exts: ['png'],
    widths: [460],
    sizes: '250px', // 250px * 1.0 aspect ratio
    alt: 'Example usage snippet from README'
  })
];

const matnrrdImages = [
  // Image is only 6kB at a width of 645px, no need to resize
  x({
    src: './matnrrd/matnrrd_1-%ww.%e',
    exts: ['png'],
    widths: [645],
    sizes: '645px',
    alt: 'Example usage snippet from README'
  }),

  x({
    src: './matnrrd/matnrrd_2-%ww.%e',
    exts: ['png'],
    widths: [1092, 961, 775, 721, 579, 437, 360, 273],
    aspectRatio: 1.37,
    alt: 'Docs for nrrdread'
  }),

  x({
    src: './matnrrd/matnrrd_3-%ww.%e',
    exts: ['png'],
    widths: [1090, 959, 774, 719, 578, 436, 360, 273],
    aspectRatio: 1.37,
    alt: 'Docs for nrrdwrite'
  })
];

const SmartShopImages = [
  x({
    src: './SmartShop/SmartShop_1-%ww.%e',
    exts: ['png'],
    widths: [676, 595, 480, 446, 358, 270, 223, 169],
    sizes: '(max-width: 520px) calc(100vw - 80px), 419px', // 250px * 1.676 aspect ratio
    aspectRatio: 1.67,
    alt: 'Main menu screen for the touch screen app'
  }),

  x({
    src: './SmartShop/SmartShop_2-%ww.%e',
    exts: ['png'],
    widths: [800, 704, 568, 528, 424, 320, 264, 200],
    aspectRatio: 1.66,
    alt: 'Purchase history screen'
  }),

  x({
    src: './SmartShop/SmartShop_3-%ww.%e',
    exts: ['png'],
    widths: [800, 704, 568, 528, 424, 320, 264, 200],
    aspectRatio: 1.67,
    alt: 'Manual item add screen'
  }),

  x({
    src: './SmartShop/SmartShop_4-%ww.%e',
    exts: ['png'],
    widths: [1024, 901, 727, 676, 543, 410, 338, 256],
    aspectRatio: 2.0,
    alt: 'CAD model for swivel screen case'
  }),

  x({
    src: './SmartShop/SmartShop_5-%ww.%e',
    exts: ['png'],
    widths: [866, 762, 615, 572, 459, 346, 286, 217],
    aspectRatio: 1.29,
    alt: 'CAD exploded model for swivel screen case'
  }),

  x({
    src: './SmartShop/SmartShop_6-%ww.%e',
    exts: ['png'],
    widths: [866, 762, 615, 572, 459, 346, 286, 217],
    aspectRatio: 1.3,
    alt: 'Close up of inside case with electronics shown'
  }),

  x({
    src: './SmartShop/SmartShop_7-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [1080, 950, 767, 713, 572, 432, 356, 270],
    aspectRatio: 0.75,
    alt: 'Image taken of 3D printed case with electronics installed'
  }),

  x({
    src: './SmartShop/SmartShop_8-%ww.%e',
    exts: ['png'],
    widths: [800, 704, 568, 528, 424, 320, 264, 200],
    aspectRatio: 1.67,
    alt: 'Settings screen for app'
  })
];

const SFSTImages = [
  x({
    src: './SFST/SFST_1-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [1440, 1267, 1022, 950, 763, 576, 475, 360],
    sizes: '(max-width: 550px) calc(100vw - 80px), 467px', // 250px * 1.87 aspect ratio
    aspectRatio: 1.87,
    alt: 'Main view of application, axial slice shown can be changed via sliders on left'
  }),

  x({
    src: './SFST/SFST_2.%e',
    exts: ['webm', 'mp4'],
    isVideo: true,
    poster: SFST_2_poster,
    alt: 'Demonstrating how to use the software'
  })
];

const EdinburgILImages = [
  x({
    src: './EdinburgIL/EdinburgIL_1-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [1440, 1267, 1022, 950, 763, 576, 475, 360],
    sizes: '(max-width: 550px) calc(100vw - 80px), (max-width: 767px) 456px, 533px', // 250px * 2.1 aspect ratio
    aspectRatio: 2.1,
    alt: 'Home page of website'
  })
];

const SBPhotographyImages = [
  x({
    src: './SBPhotography/SBPhotography_1-%ww.%e',
    exts: ['jpg', 'webp'],
    widths: [1440, 1267, 1022, 950, 763, 576, 475, 360],
    sizes: '(max-width: 550px) calc(100vw - 80px), (max-width: 767px) 456px, 533px', // 250px * 2.1 aspect ratio
    aspectRatio: 2.1,
    alt: 'Home page of website'
  })
];

export {
  PATSImages,
  SDFSAImages,
  EndocardialSegmentationImages,
  matnrrdImages,
  CTLAImages,
  CTLIImages,
  pynrrdImages,
  SmartShopImages,
  SFSTImages,
  EdinburgILImages,
  SBPhotographyImages
};
