import React, { Component } from 'react';
import { Card, Row, Col, CardHeader, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ListItem extends Component {
  render() {
    return (
      <li className={this.props.break ? 'mb-3' : ''}>
        <FontAwesomeIcon icon="check" />
        {this.props.children}
      </li>
    );
  }
}

class LibrarySkills extends Component {
  render() {
    return (
      <Row>
        <Col>
          <h4>Libraries</h4>
          <ul className="list-checkmark">
            <ListItem>
              <a href="https://anaconda.org/">Anaconda</a>
            </ListItem>
            <ListItem>
              <a href="https://itk.org/">ITK</a>
            </ListItem>
            <ListItem>
              <a href="https://opencv.org/">OpenCV</a>
            </ListItem>
            <ListItem>
              <a href="https://www.qt.io/">Qt</a>
            </ListItem>
            <ListItem>
              <a href="https://vtk.org/">VTK</a>
            </ListItem>
          </ul>
        </Col>
      </Row>
    );
  }
}

class Skills extends Component {
  render() {
    return (
      <Row id="skills" className="section">
        <Col>
          <Card className="minimalist">
            <CardHeader>Skills</CardHeader>
            <CardBody>
              <Row>
                <Col xs="12" sm="6" lg="4">
                  <Row>
                    <Col xs="12" className="mb-xsm-0 px-xs-0">
                      <h4>Languages</h4>
                      <ul className="list-checkmark">
                        <ListItem>C/C++</ListItem>
                        <ListItem>Python</ListItem>
                        <ListItem>Matlab</ListItem>
                        <ListItem break>C#</ListItem>

                        <ListItem>Java</ListItem>
                        <ListItem break>Swift</ListItem>

                        <ListItem>Javascript</ListItem>
                        <ListItem>HTML/CSS</ListItem>
                        <ListItem break>PHP</ListItem>

                        <ListItem>SQL &amp; NoSQL</ListItem>
                        <ListItem>Visual Basic</ListItem>
                        <ListItem break>Verilog</ListItem>

                        <ListItem>Markdown</ListItem>
                        <ListItem>reStructuredText</ListItem>
                        <ListItem>LaTeX</ListItem>
                      </ul>
                    </Col>
                    <Col xs="12" className="show-sm-md px-xs-0">
                      <Row>
                        <Col className="mb-50">
                          <h4>Soft Skills</h4>
                          <ul className="list-checkmark">
                            <ListItem>Effective communicator</ListItem>
                            <ListItem>Self-motivated</ListItem>
                            <ListItem>Detail-oriented</ListItem>
                            <ListItem>Hard working</ListItem>
                            <ListItem>Excellent leadership abilities</ListItem>
                          </ul>
                        </Col>
                      </Row>
                      <LibrarySkills />
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" sm="6" lg="4" className="hide-sm-md px-xs-0">
                  <Row>
                    <Col className="mb-50">
                      <h4>Soft Skills</h4>
                      <ul className="list-checkmark">
                        <ListItem>Effective communicator</ListItem>
                        <ListItem>Self-motivated</ListItem>
                        <ListItem>Detail-oriented</ListItem>
                        <ListItem>Hard working</ListItem>
                        <ListItem>Excellent leadership abilities</ListItem>
                      </ul>
                    </Col>
                  </Row>
                  <LibrarySkills />
                </Col>

                <Col xs="12" sm="6" lg="4" className="px-xs-0">
                  <h4>Tools</h4>
                  <ul className="list-checkmark">
                    <ListItem>Windows</ListItem>
                    <ListItem>Linux</ListItem>
                    <ListItem break>Mac OSX</ListItem>

                    <ListItem>Git (GitHub)</ListItem>
                    <ListItem break>Travis CI</ListItem>

                    <ListItem>PyCharm</ListItem>
                    <ListItem break>Visual Studio</ListItem>

                    <ListItem>Android Studio</ListItem>
                    <ListItem break>XCode</ListItem>

                    <ListItem>Arduino</ListItem>
                    <ListItem>LTSpice</ListItem>
                    <ListItem>EAGLE (PCB)</ListItem>
                    <ListItem>Siemens NX</ListItem>
                    <ListItem break>Inventor (CAD)</ListItem>

                    <ListItem>Node.js</ListItem>
                    <ListItem>React</ListItem>
                    <ListItem break>MongoDB</ListItem>

                    <ListItem>Photoshop</ListItem>
                    <ListItem>Illustrator</ListItem>
                    <ListItem>InDesign</ListItem>
                  </ul>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Skills;
