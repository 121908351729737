function checkWebPSupport() {
  // Check for WebP support by loading a small sample image and see if it fails
  // A global constant is set in the document to indicate whether support is available
  return new Promise(function(resolve, reject) {
    const image = new Image();
    image.onload = image.onerror = function() {
      document.supportsWebP = image.height === 2;
      resolve();
    };

    image.src =
      'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
  });
}

export default checkWebPSupport;
