import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import NewTargetLink from './utils';

class Footer extends Component {
  render() {
    return (
      <footer>
        <Container>
          <Row>
            <Col xs="12">
              <small>Copyright &copy; 2019 Addison Elliott</small>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <small>
                Made with <NewTargetLink href="https://getbootstrap.com/">Bootstrap 4</NewTargetLink> and{' '}
                <NewTargetLink href="https://reactjs.org/">React.js</NewTargetLink>
              </small>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
