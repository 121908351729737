class MobileStickyHoverFixPlugin {
  constructor(touchClass = 'is-touching') {
    // Indicates whether user is using touch input
    this.isTouchInput = false;

    // Timer for delaying touch and disregarding the immediate onMouseOver call
    this.touchTimer = undefined;

    // Whether the touch class has been applied to the root
    this.hasTouchClass = false;

    // Touch class to add/remove to body, this is used to remove hover attribute in CSS
    // This class will be added to the body when touching
    // This class will be absent if hovering with a mouse pointer
    this.touchClass = touchClass;

    // Bind functions
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.mount = this.mount.bind(this);
    this.unmount = this.unmount.bind(this);
  }

  onTouchStart(e) {
    // Reset the touch timer, start it over again since we're touching again
    clearTimeout(this.touchTimer);

    // Set to true since this function is only called on touch input
    this.isTouchInput = true;

    // Add the touch class if it isnt added already
    if (!this.hasTouchClass) {
      this.hasTouchClass = true;
      document.documentElement.classList.add(this.touchClass);
    }

    // Adds a delay to prevent onMouseOver being called and removing the class right after
    this.touchTimer = setTimeout(function() {
      this.isTouchInput = false;
    }, 500);
  }

  onMouseOver(e) {
    // If the document has the touch class but there is no touch input, then remove the touch class
    if (this.hasTouchClass && !this.isTouchInput) {
      this.isTouchInput = false;
      this.hasTouchClass = false;
      document.documentElement.classList.remove(this.touchClass);
    }
  }

  mount() {
    // Touchstart event is called by mobile browsers when the user presses on the screen with their finger. This is not called
    // on desktop browsers.
    // The mouseover event is called by desktop browsers when the pointer moves but also is called after the touchstart event for
    // touch devices
    // Mount these two handlers to enable adding/removing the touchClass based on if the user is touching or using mouse pointer
    document.addEventListener('touchstart', this.onTouchStart, false); //this event only gets called when input type is touch
    document.addEventListener('mouseover', this.onMouseOver, false); //this event gets called when input type is everything from touch to
  }

  unmount() {
    // Unmount the components
    document.removeEventListener('touchstart', this.onTouchStart, false);
    document.removeEventListener('mouseover', this.onMouseOver, false);
  }
}

export default MobileStickyHoverFixPlugin;
