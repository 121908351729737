import React, { Component } from 'react';
import {
  Card,
  Row,
  Col,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText,
  CardBody,
  Nav,
  NavItem,
  Badge,
  NavLink
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lightbox from 'react-images';

import NewTargetLink from './utils';
import { loadResponsiveImages, createSrcSetString, ResponsiveImage } from './responsiveImage';

import {
  PATSImages,
  SDFSAImages,
  EndocardialSegmentationImages,
  matnrrdImages,
  CTLAImages,
  CTLIImages,
  pynrrdImages,
  SmartShopImages,
  SFSTImages,
  EdinburgILImages,
  SBPhotographyImages
} from './projectImages';

// Context created in order to dynamically load all responsive images
// Webpack unable to deduce what to package up when includes are dynamic
// Note: Anytime this context is used (e.g. ResponsiveImage), the path must be relative to this path, so ./profile-picture would actually be ./assets/images/profile-picture
const imagesContext = require.context('./assets/images', true, /^.*$/);

class Project extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentLightboxImage: 0,
      lightboxIsOpen: false
    };

    // Bind components
    this.showLightbox = this.showLightbox.bind(this);
    this.hideLightbox = this.hideLightbox.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoImage = this.gotoImage.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
    this.customImage = this.customImage.bind(this);
    this.customPreloadImage = this.customPreloadImage.bind(this);
  }

  showLightbox(index, event) {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      currentLightboxImage: index,
      lightboxIsOpen: true
    });
  }

  hideLightbox() {
    this.setState({
      lightboxIsOpen: false
    });
  }

  gotoPrevious() {
    this.setState({
      currentLightboxImage: this.state.currentLightboxImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentLightboxImage: this.state.currentLightboxImage + 1
    });
  }

  gotoImage(index) {
    this.setState({
      currentLightboxImage: index
    });
  }

  handleClickImage() {
    if (this.state.currentLightboxImage === this.props.images.length - 1) return;

    this.gotoNext();
  }

  /**
   * Calculates Lightbox's image sizes attribute used for determining appropriate image to choose from srcset attribute
   *
   * Uses width & height offset (i.e. padding) for the Lightbox and properties of the image (e.g. aspect ratio) to calculate the sizes
   *
   * This is used for the image preload handler and the image handler itself
   *
   * @param {object} image
   * @param {int} widthOffset
   * @param {int} heightOffset
   */
  calculateSizes(image, widthOffset, heightOffset) {
    // No need for sizes if there is only one width (or no width specified)
    // Note: Return an empty string and not null or undefined because Image.sizes (and src) will convert to a string and thus it'll try to load "undefined" or
    // "null" rather than doing nothing
    if (!image.widths || image.widths.length === 1) return '';

    // Maximum width of an image, setting is set in lightbox and 1024px is the default value
    // If the image width itself is smaller than the maximum value, then we cap it at that otherwise the image will be stretched to the max
    // size and will look blurry.
    // Note: This assumes the largest image width will be specified first, a fair assumption/requirement to make
    const maxWidth = Math.min(1024, image.widths[0]);

    // Size for screen at which the maximum image size will be reached (width = maxWidth)
    // For the width, this is simply the maximum image width plus the horizontal padding (i.e. offset)
    const screenMaxWidth = maxWidth + widthOffset;
    // For the height, this finds the maximum height by taking maximum image width divided by image aspect ratio and then adding the vertical padding
    const screenMaxHeight = Math.round(maxWidth / image.aspectRatio + heightOffset);

    // Effective aspect ratio for displaying an image, the width/height offsets are subtracted from the width/height
    // Note: Forced to use document.documentElement.clientWidth because it contains the actual width of the view, while window.screen.width and similar
    // variants contains the device width regardless of the orientation of the device.
    // const lightboxAspectRatio = (window.screen.availWidth - widthOffset) / (window.screen.availHeight - heightOffset);
    const lightboxAspectRatio =
      (document.documentElement.clientWidth - widthOffset) / (document.documentElement.clientHeight - heightOffset);

    // If the lightbox aspect ratio is smaller than the image aspect ratio, then that means the width is constraining the image
    // Otherwise the height is constraining the image
    // Note: This is NOT apart of the media query because CSS does not support decimal display ratios, they only have predetermined ratios like 16/9, 4/3, etc
    // My initial gut reaction that this solution isn't as versatile because if the user changes the browser window size or somehow it changes, then this function
    // would not be called again and the image may be sized wrong. But, when testing, I noticed that this function is called whenever the browser is resized so there
    // is minimal issues with using this method.
    //
    // Note2: Another note is that iOS 9.3.5 (Chrome, Firefox & Safari) do not support multiple queries in the sizes attribute. Meaning in one media query, you cannot
    // put and/or between two statements. Originally had a min-width and min-height statement but since we know if the image is width or height constrained based on the
    // if statement, it's unnecessary
    let sizes;
    if (lightboxAspectRatio < image.aspectRatio) {
      sizes = `(min-width: ${screenMaxWidth}px) ${maxWidth}px,
                  calc(100vw - ${widthOffset}px)`;
    } else {
      sizes = `(min-height: ${screenMaxHeight}px) ${maxWidth}px,
                  calc(${image.aspectRatio} * (100vh - ${heightOffset}px))`;
    }

    return sizes;
  }

  customImage(image, imageLoaded, figureClassName, imgClassName, onClickImage, widthOffset, heightOffset) {
    // If the image is a video (best way to show animation, GIFs are terrible), then display a video frame
    if (image.isVideo) {
      const webmVideo = image.exts.includes('webm')
        ? imagesContext(image.src.replace(new RegExp('%e', 'g'), 'webm'))
        : null;
      const mp4Video = image.exts.includes('mp4')
        ? imagesContext(image.src.replace(new RegExp('%e', 'g'), 'mp4'))
        : null;
      const oggVideo = image.exts.includes('ogg')
        ? imagesContext(image.src.replace(new RegExp('%e', 'g'), 'ogg'))
        : null;

      return (
        <video className={imgClassName} autoPlay controls poster={image.poster}>
          {webmVideo && <source src={webmVideo} type="video/webm" />}
          {mp4Video && <source src={mp4Video} type="video/mp4" />}
          {oggVideo && <source src={oggVideo} type="video/ogg" />}
          Your browser does not support the video tag.
        </video>
      );
    }

    // Calculate the sizes attribute
    const sizes = this.calculateSizes(image, widthOffset, heightOffset);

    return (
      <figure className={figureClassName}>
        <ResponsiveImage
          context={imagesContext}
          src={image.src}
          exts={image.exts}
          widths={image.widths}
          sizes={sizes}
          alt={image.alt}
          className={imgClassName}
          style={{
            cursor: onClickImage ? 'pointer' : 'auto',
            maxHeight: `calc(100vh - ${heightOffset}px)`
          }}
          onClick={onClickImage}
        />
      </figure>
    );
  }

  customPreloadImage(imageData, onload, widthOffset, heightOffset) {
    const image = new Image();
    image.onerror = onload;
    image.onload = onload;

    // Do nothing for a video, just load an empty src so it will call the onload callback
    if (imageData.isVideo) {
      image.src = '';
      return image;
    }

    // TODO Look into using better video options rather than GIF
    // TODO Add placeholder/blurred image support for profile picture and potentially background image

    // Calculate the sizes attribute, will return null if no widths are given meaning there is only one sized image to load
    image.sizes = this.calculateSizes(imageData, widthOffset, heightOffset);

    // Get the extension to load, use WebP if browser supports it and available, otherwise use the default
    // In the case that no extensions are given, then give a blank string and loadResponsiveImages will work fine by not doing anything about the extension
    let ext = '';
    if (imageData.exts) {
      // Confusing bit of logic to basically get the appropriate extension to load for the image
      // webpIndex is the index of the webp extension with it being -1 if it does not exist for this image
      const webpIndex = imageData.exts.indexOf('webp');
      // Index for normal image format, basically the opposite of the webpIndex
      const normalIndex = webpIndex === 0 ? 1 : 0;
      // The actual index used will be 0 if there is no WebP index OR if there is only one extension available (doesnt matter if its WebP or not, we just load that)
      // Otherwise, if the brwoser supports WebP, use the webpIndex, otherwise use the normal index
      const index =
        webpIndex === -1 || imageData.exts.length === 1 ? 0 : document.supportsWebP ? webpIndex : normalIndex;

      ext = imageData.exts[index];
    }

    // Load the image paths based on the src, desired extension & the widths
    const images = loadResponsiveImages(imagesContext, imageData.src, ext, imageData.widths);

    // If there is more than one size image, then create a srcset string, otherwise set to undefined
    image.srcset = image.sizes ? createSrcSetString(images, imageData.widths) : '';
    // Used for IE and other browsers that do not support the srcset attribute, this forces the callback to be called immediately, effectively disabling preloading
    // If there is only one image, then set the src to be the only image src
    image.src = image.sizes ? '' : images[0];

    return image;
  }

  render() {
    const image = this.props.images !== undefined ? this.props.images[0] : undefined;

    return (
      <Col
        xs="12"
        lg="6"
        className={
          this.props.filters
            ? this.props.filters.concat(['px-smx-d-0', 'project-all']).join(' ')
            : 'px-smx-d-0 project-all'
        }
      >
        <Card>
          {image !== undefined && (
            <div
              className="card-image-container"
              onClick={event => {
                this.showLightbox(0, event);
              }}
            >
              <ResponsiveImage
                context={imagesContext}
                src={image.src}
                exts={image.exts}
                widths={image.widths}
                sizes={image.sizes}
                alt={image.alt}
                className="card-img-top"
                onClick={event => {
                  this.showLightbox(0, event);
                }}
              />
            </div>
          )}
          <CardBody>
            <Row>
              <Col xs="12" className="col-smxx-10 pr-0 m-0">
                <CardTitle>
                  <NewTargetLink href={this.props.websiteLink || this.props.githubLink}>
                    {this.props.title}
                  </NewTargetLink>
                </CardTitle>
              </Col>
              <Col xs="12" className="col-smxx-2 pl-smxx-0 m-0 text-smxx-right">
                {this.props.websiteLink && (
                  <NewTargetLink href={this.props.websiteLink}>
                    <FontAwesomeIcon icon="globe-americas" size="2x" />
                  </NewTargetLink>
                )}
                {this.props.githubLink && (
                  <NewTargetLink href={this.props.githubLink}>
                    <FontAwesomeIcon icon={['fab', 'github']} size="2x" />
                  </NewTargetLink>
                )}
              </Col>
            </Row>

            <CardSubtitle>
              <ul className="technology-list">
                {this.props.technologies.map((language, index) => {
                  return (
                    <li key={index}>
                      <Badge color="custom" pill>
                        {language}
                      </Badge>
                    </li>
                  );
                })}
              </ul>
            </CardSubtitle>
            <CardText tag="div" className="card-desc">
              {this.props.children}
            </CardText>

            {this.props.images !== undefined && (
              <Lightbox
                currentImage={this.state.currentLightboxImage}
                images={this.props.images}
                isOpen={this.state.lightboxIsOpen}
                onClickImage={this.handleClickImage}
                onClickNext={this.gotoNext}
                onClickPrev={this.gotoPrevious}
                onClose={this.hideLightbox}
                customImage={this.customImage}
                customPreloadImage={this.customPreloadImage}
                preloadNextImage={true}
                showThumbnails={false}
                backdropClosesModal
              />
            )}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentFilter: 'project-all'
    };

    // Bind components
    this.filter = this.filter.bind(this);
  }

  filter(type) {
    let parent = document.querySelector('.projects-row');

    // Change active navbar by updating the state
    this.setState({
      currentFilter: type
    });

    Array.from(parent.children).forEach(element => {
      // Get whether the project should be shown and whether it is currently being shown
      const shouldShow = element.classList.contains(type);
      const isHidden = element.classList.contains('project-hide');

      if (shouldShow && isHidden) {
        element.classList.remove('project-hide');
      } else if (!shouldShow && !isHidden) {
        element.classList.add('project-hide');
      }
    });
  }

  render() {
    return (
      <Row id="projects" className="section">
        <Col>
          <Card className="minimalist">
            <CardHeader>Projects</CardHeader>
            <CardBody>
              <Row className="language-row">
                <Col xs="auto">
                  <Nav className="language-nav" pills>
                    <NavItem>
                      <NavLink
                        href="#"
                        active={this.state.currentFilter === 'project-all'}
                        onClick={this.filter.bind(this, 'project-all')}
                      >
                        All
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        active={this.state.currentFilter === 'project-python'}
                        onClick={this.filter.bind(this, 'project-python')}
                      >
                        Python
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        active={this.state.currentFilter === 'project-android'}
                        onClick={this.filter.bind(this, 'project-android')}
                      >
                        Android
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        active={this.state.currentFilter === 'project-ios'}
                        onClick={this.filter.bind(this, 'project-ios')}
                      >
                        iOS
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        active={this.state.currentFilter === 'project-web'}
                        onClick={this.filter.bind(this, 'project-web')}
                      >
                        Web
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        active={this.state.currentFilter === 'project-cpp'}
                        onClick={this.filter.bind(this, 'project-cpp')}
                      >
                        C++
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        active={this.state.currentFilter === 'project-matlab'}
                        onClick={this.filter.bind(this, 'project-matlab')}
                      >
                        MATLAB
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
              <Row>
                <CardText className="disclaimer">Click on project image to see more screenshots.</CardText>
              </Row>
              <Row className="projects-row">
                <Project
                  title="PATS"
                  technologies={['Python', 'VTK', 'PyQt5', 'Scipy']}
                  filters={['project-python']}
                  images={PATSImages}
                >
                  <p>
                    Package for Adipose Tissue Segmentation (PATS) is a Python application I wrote for my thesis in
                    graduate school. The purpose of the application is to allow easy visualization and manipulation of
                    3D medical data. This includes things like loading in 3D data, tracing or drawing on the data,
                    running algorithms on the data, and more. PATS features a modular setup to allow for easy creation
                    of new modules. The goal is to allow graduate students in the lab to create their own module apart
                    of the application that enables anyone in the lab to use their algorithm.
                  </p>
                  <span className="font-weight-bold">Accomplishments:</span>
                  <ul className="mb-0">
                    <li>Over 10k lines of code</li>
                    <li>
                      Module panel allows for others in the lab to create their own module to interact with the data
                    </li>
                    <li>Loads file formats such as NiFTi, NRRD, DICOM, PNG, JPG, MP4</li>
                    <li>Able to load MRI scans, Ultrasound images &amp; other modalities</li>
                    <li>Displays sagittal, coronal &amp; transverse planes</li>
                  </ul>
                </Project>

                <Project
                  title="MRI Fat Segmentation Algorithm"
                  technologies={['Python', 'PyQt5', 'ITK', 'OpenCV', 'Scikit-image']}
                  filters={['project-python']}
                  images={SDFSAImages}
                  githubLink="https://github.com/SIUE-BiomedicalImagingResearchLab/SIUE-Dixon-Fat-Segmentation-Algorithm"
                >
                  <p>
                    This project is an algorithm I developed as part of my graduate-level thesis. The purpose of the
                    program is to take in Dixon MRI scans and calculate the various depots of fat (adipose tissue) in
                    the scan. This algorithm has been ran on more than 50 scans over the course of 2 years with good
                    results. More information can be found in the publication.
                  </p>
                  <span className="font-weight-bold">Accomplishments:</span>
                  <ul>
                    <li>Uses classical image segmentation methods to segment various types of fat from MRI scan</li>
                    <li>Incorporates an active contour to find abdominal cavity</li>
                    <li>Includes configure GUI to allow easy selection of user landmarks</li>
                    <li>Works on three separate types of MRI scans used in our lab</li>
                    <li>Eventually will be integrated into PATS as a module.</li>
                  </ul>
                  <p>
                    <span className="font-weight-bold">Publication: </span>
                    <span style={{ fontSize: '0.85rem' }}>
                      Klingensmith, J. Elliott, A. Givan, A. Faszold, Z. Mahan, C. Doedtman, A. Fernandez-del-Valle, M.
                      Development and evaluation of a method for segmentation of cardiac, subcutaneous, and visceral
                      adipose tissue from Dixon magnetic resonance images. J. Med. Imag. 6(1), 014004 (2019), doi:
                      10.1117/1.JMI.6.1.014004.
                    </span>
                  </p>
                </Project>

                <Project
                  title="IQ to B-Mode Conversion Algorithm"
                  technologies={['Python', 'Numpy', 'Scipy']}
                  filters={['project-python']}
                >
                  <p>
                    Algorithm developed as part of a project in an advanced digital signal processing (DSP) course taken
                    during graduate school. This algorithm converts raw RF &amp; IQ signals from an ultrasound machine
                    into a traditional B-mode image.
                  </p>
                  <span className="font-weight-bold">Accomplishments:</span>
                  <ul>
                    <li>
                      Loads proprietary <span className="font-italic">.iq</span> file format from Zonare ZS3 Ultrasound
                      machine
                    </li>
                    <li>Includes time-gain compensation (TGC) parameters</li>
                    <li>Includes dynamic-range (DR) parameters</li>
                    <li>
                      Implements a variety of noise reduction algorithms, such as Wavelet's transform and Gaussian
                      filter
                    </li>
                    <li>Ran algorithm on echocardiograms obtained from lab</li>
                  </ul>
                  <p>
                    <span className="font-weight-bold">Publication: </span>
                    <NewTargetLink href="files/Elliott_Unpublished_Abstract_2018.pdf">
                      Conversion of ultrasound IQ data to B-mode images
                    </NewTargetLink>
                  </p>
                </Project>

                <Project
                  title="Endocardial Segmentation Algorithm"
                  technologies={['Python', 'Numpy']}
                  filters={['project-python']}
                  images={EndocardialSegmentationImages}
                >
                  <p>
                    Algorithm developed as part of a project in a graduate-level optimal control course. The algorithm
                    uses B-mode parasternal short-axis (PSAX) echocardiograms to segment the endocardial boundary of the
                    heart using dynamic programming with Dijkstra's algorithm. See the abstract or poster publications
                    below for more information.
                  </p>
                  <span className="font-weight-bold">Accomplishments:</span>
                  <ul>
                    <li>B-mode images smoothed using anisotropic diffusion</li>
                    <li>Implement GUI for user to select landmarks for algorithm</li>
                    <li>
                      Validate algorithm against one expert tracer against 15 scans at end-systole and end-diastole
                    </li>
                  </ul>
                  <p>
                    <span className="font-weight-bold">Publication: </span>
                    <span style={{ fontSize: '0.85rem' }}>
                      Elliott, A., Karlapalem, A., Givan, A., Fernandez-del-Valle, M., Klingensmith, J. Segmentation of
                      Endocardium in Parasternal Short-axis Echocardiograms using Dynamic Programming. 2018 Biomedical
                      Engineering Society Annual Meeting; 2018 Oct 19; Atlanta, GA.{' '}
                      <NewTargetLink href="files/Elliott_BMES_Poster_2018.pdf">Download Poster</NewTargetLink>{' '}
                      <NewTargetLink href="files/Elliott_BMES_Abstract_2018.pdf">Download Abstract</NewTargetLink>
                    </span>
                  </p>
                </Project>

                <Project
                  title="Custody Transfer Log"
                  technologies={['Android', 'Java']}
                  filters={['project-android']}
                  images={CTLAImages}
                >
                  <p>
                    Android app created over a summer where I was hired as a freelance developer. The purpose of Custody
                    Transfer Log (CTL) is to allow easy storage and tracking of child custody in divorce cases involving
                    children. CTL keeps an immutable log of date, time &amp; place when picking up or dropping of your
                    children to your ex-spouse.
                  </p>
                  <span className="font-weight-bold">Accomplishments:</span>
                  <ul>
                    <li>Designed and developed entire app single-handedly</li>
                    <li>Authentication performed using Parse Server backend</li>
                    <li>App uses Material design for modern look</li>
                    <li>Uses Google Maps API to show user's current location</li>
                    <li>App includes generate report feature that creates a PDF on the fly of event data</li>
                  </ul>
                  <p>
                    <span className="font-weight-bold">App Link: </span>
                    <NewTargetLink href="https://play.google.com/store/apps/details?id=com.sociallocalmobile.custodytransferlog">
                      Custody Transfer Log
                    </NewTargetLink>
                  </p>
                </Project>

                <Project
                  title="Custody Transfer Log"
                  technologies={['iOS', 'XCode', 'Swift 4']}
                  filters={['project-ios']}
                  images={CTLIImages}
                >
                  <p>
                    iOS app created using Swift 4 in XCode 10 over a summer where I was hired as a freelance developer.
                    The purpose of Custody Transfer Log (CTL) is to allow easy storage and tracking of child custody in
                    divorce cases involving children. CTL keeps an immutable log of date, time &amp; place when picking
                    up or dropping of your children to your ex-spouse.
                  </p>
                  <span className="font-weight-bold">Accomplishments:</span>
                  <ul>
                    <li>Designed and developed entire app single-handedly</li>
                    <li>Authentication performed using Parse Server backend</li>
                    <li>Uses Google Maps API to show user's current location</li>
                    <li>App includes generate report feature that creates a PDF on the fly of event data</li>
                  </ul>
                  <p>
                    <span className="font-weight-bold">App Link: </span>
                    <NewTargetLink href="https://itunes.apple.com/us/app/custody-transfer-log/id1437923533#?platform=iphone">
                      Custody Transfer Log
                    </NewTargetLink>
                  </p>
                </Project>

                <Project
                  title="pynrrd"
                  technologies={['Python', 'Numpy']}
                  filters={['project-python']}
                  githubLink="https://github.com/mhe/pynrrd"
                  images={pynrrdImages}
                >
                  <p>
                    Python package for reading &amp; writing{' '}
                    <NewTargetLink href="http://teem.sourceforge.net/nrrd/">NRRD</NewTargetLink> files. The NRRD file
                    format is popular in the medical imaging community for storing multi-dimensional data such as MRI
                    scans.
                  </p>
                  <p>
                    This package was originally developed by Maarten H. Everts but I became the primary maintainer of
                    the project in August 2018.
                  </p>
                  <span className="font-weight-bold">Accomplishments:</span>
                  <ul>
                    <li>TravisCI is setup to allow for automatic testing and code coverage results</li>
                    <li>
                      Created documentation using Sphinx that is automatically generated from GitHub page. Docs can be
                      seen <NewTargetLink href="https://pynrrd.readthedocs.io/en/latest/">here</NewTargetLink>
                    </li>
                  </ul>
                </Project>

                <Project
                  title="matnrrd"
                  technologies={['Matlab']}
                  filters={['project-matlab']}
                  images={matnrrdImages}
                  githubLink="https://github.com/addisonElliott/matnrrd"
                >
                  <p>
                    Matlab library for reading &amp; writing{' '}
                    <NewTargetLink href="http://teem.sourceforge.net/nrrd/">NRRD</NewTargetLink> files. The NRRD file
                    format is popular in the medical imaging community for storing multi-dimensional data such as MRI
                    scans.
                  </p>
                  <p>
                    Project is publicly available on{' '}
                    <NewTargetLink href="https://www.mathworks.com/matlabcentral/fileexchange/66658-addisonelliott-matnrrd">
                      Matlab file exchange
                    </NewTargetLink>{' '}
                    as well.
                  </p>
                </Project>

                <Project
                  title="polarTransform"
                  technologies={['Python', 'Numpy', 'Scipy', 'Scikit-image']}
                  filters={['project-python']}
                  githubLink="https://github.com/addisonElliott/polarTransform"
                >
                  <p>
                    polarTransform is a Python package for converting images between the polar and Cartesian domain. I
                    created this package during my graduate studies for use in the IQ to B-mode conversion algorithm.
                  </p>
                  <span className="font-weight-bold">Accomplishments:</span>
                  <ul>
                    <li>Supports start &amp; stop angle and radius arguments</li>
                    <li>Supports interpolation order (e.g. bicubic, linear, nearest) for conversion</li>
                    <li>TravisCI is setup to allow for automatic testing and code coverage results</li>
                    <li>
                      Created documentation using Sphinx that is automatically generated from GitHub page. Docs can be
                      seen <NewTargetLink href="https://polartransform.readthedocs.io/en/latest/">here</NewTargetLink>
                    </li>
                  </ul>
                </Project>

                <Project
                  title="pyqt5ac"
                  technologies={['Python', 'PyQt5']}
                  filters={['project-python']}
                  githubLink="https://github.com/addisonElliott/pyqt5ac"
                >
                  <p>
                    pyqt5ac is a Python package for automatically compiling Qt's UI and QRC files into Python files on
                    demand. This package can be used from a command-line interface or configuration settings can be
                    saved in a <NewTargetLink href="https://yaml.org/">YAML</NewTargetLink> file. Files will only be
                    compiled if they have been edited since the last compilation.
                  </p>
                  <p>This package is used in PATS and in the MRI Fat Segmentation Algorithm project. </p>
                </Project>

                <Project
                  title="pypdn"
                  technologies={['Python', 'Numpy']}
                  filters={['project-python']}
                  githubLink="https://github.com/addisonElliott/pypdn"
                >
                  <p>
                    pypdn is a Python package for reading and writing Paint.NET (PDN) images. The free and open-source
                    software tool <NewTargetLink href="https://www.getpaint.net/">Paint.NET</NewTargetLink> defaults to
                    saving images in it's proprietary PDN file format. The benefit of this format over BMP, PNG, JPEG,
                    etc is that it stores layer information and properties that are not present in these traditional
                    image formats.
                  </p>
                </Project>

                <Project
                  title="pydicomext"
                  technologies={['Python', 'Numpy']}
                  filters={['project-python']}
                  githubLink="https://github.com/addisonElliott/pydicomext"
                >
                  <p>
                    pydicomext is an extension to the{' '}
                    <NewTargetLink href="https://github.com/pydicom/pydicom">pydicom</NewTargetLink> Python package
                    which allows reading and writing of{' '}
                    <NewTargetLink href="https://www.dicomstandard.org/">DICOM</NewTargetLink> files. This module
                    extends pydicom to allow loading entire directories of DICOM files and organizing the data in terms
                    of patients, studies and series for easy traversal and identification of scans. In addition,
                    pydicomext contains functions to manipulate series, merge two series together and even combine
                    series into a Numpy volume is provided.
                  </p>
                </Project>

                <Project
                  title="Smart Shop"
                  technologies={['Python', 'PyQt5', 'SQL']}
                  filters={['project-python']}
                  images={SmartShopImages}
                  githubLink="https://github.com/addisonElliott/SmartShopTouchScreen/"
                >
                  <p>
                    Senior design project completed during my undergraduate studies. Smart Shop is a compact, easy
                    system that manages inventory for common household items to allow users to see what they have on
                    hand and even predict when they will run out of an item. It features a dedicated device running a
                    Raspberry Pi 3 with a 7" touchscreen, two wireless barcode scanners for easy item entry, and a
                    specially designed swivel case to change the viewing angle of the touchscreen.
                  </p>
                  <span className="font-weight-bold">Accomplishments:</span>
                  <ul>
                    <li>Custom 3D printed case designed using Autodesk Inventor CAD software</li>
                    <li>Software for touch screen created using Python and PyQt5</li>
                    <li>Software designed with touch screen interface in mind and styled using CSS</li>
                    <li>PostgreSQL used for database backend for storing items on hand</li>
                    <li>Prediction algorithm that tracks daily usage of items and estimates when you will run out</li>
                  </ul>
                </Project>

                <Project
                  title="SIUE Fat Segmentation Tool"
                  technologies={['C++', 'Qt', 'ITK', 'OpenCV', 'OpenGL']}
                  filters={['project-cpp']}
                  images={SFSTImages}
                  githubLink="https://github.com/SIUE-BiomedicalImagingResearchLab/SIUE-Fat-Segmentation-Tool"
                >
                  <p>
                    Desktop application developed using Qt Creator with OpenGL for the rendering platform. This tool is
                    used to load Dixon MRI scans, view the data and subsequently allow the user to trace around the fat
                    deposits on the transverse plane of the 3D data. Four tracers used this software to each trace over
                    400 transverse slices amongst 24 scans. This traced data was then used to validate a segmentation
                    algorithm by comparing the expert tracer's results with the computer algorithm results.
                  </p>
                  <span className="font-weight-bold">Accomplishments:</span>
                  <ul>
                    <li>
                      Implements blending of fat-only image and water-only image with ability to tune opacity for both
                    </li>
                    <li>Includes eraser &amp; with configurable brush size to remove part of traced results</li>
                    <li>Ability to load &amp; save tracing results for later viewing</li>
                    <li>Implement undo &amp; redo functionality for tracing</li>
                    <li>Features a coronal view along with crosshairs on both views</li>
                    <li>Supports 20 colormaps for fat-only and water-only image</li>
                  </ul>
                  <p>
                    <span className="font-weight-bold">Publication: </span>
                    <span style={{ fontSize: '0.85rem' }}>
                      Klingensmith J., Elliott A., Fernandez-Del-Valle M., Mitra S. Automated segmentation of cardiac
                      adipose tissue in Dixon magnetic resonance images. Journal of Biomedical Graphics and Computing,
                      2018, 8(1):1-13.{' '}
                      <NewTargetLink href="https://doi.org/10.5430/jbgc.v8n1p1">
                        https://doi.org/10.5430/jbgc.v8n1p1
                      </NewTargetLink>
                    </span>
                  </p>
                </Project>

                <Project
                  title="Edinburg IL"
                  technologies={['HTML', 'CSS', 'PHP']}
                  filters={['project-web']}
                  websiteLink="https://www.edinburgil.com/"
                  images={EdinburgILImages}
                >
                  <p>Community website for the city of Edinburg, IL.</p>
                  <span className="font-weight-bold">Accomplishments:</span>
                  <ul>
                    <li>Responsive, mobile-friendly design using CSS3 and media queries</li>
                    <li>Backend completed using PHP with custom admin panel and WSIWYG content editor</li>
                  </ul>
                </Project>

                <Project
                  title="SB Photography"
                  technologies={['HTML', 'CSS', 'PHP', 'Wordpress']}
                  filters={['project-web']}
                  websiteLink="https://www.sbphotography.net/"
                  images={SBPhotographyImages}
                >
                  <p>
                    Website for photographer located in Springfield, IL. The website was designed using WordPress and a
                    premium theme. Minor adjustments were made to the theme's HTML &amp; CSS for customization.
                  </p>
                </Project>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Projects;
