import './assets/scss/app.scss';

import 'focus-visible/dist/focus-visible.min.js';
// Special plugin for picturefill that will detect DOM changes and polyfill picture tags accordingly
// Necessary since the images in the Lightbox do not appear until the Lightbox appears and thus will not be polyfilled like other images
import 'picturefill/dist/plugins/mutation/pf.mutation.min.js';

import picturefill from 'picturefill';
import checkWebPSupport from './webpSupport';

import React, { Component } from 'react';
import { Container } from 'reactstrap';

import MobileStickyHoverFixPlugin from './mobileStickyHoverFixPlugin';
import Header from './header';
import Home from './home';
import About from './about';
import Skills from './skills';
import Projects from './projects';
import Experience from './experience';
import Education from './education';
import Footer from './footer';

class App extends Component {
  constructor(props) {
    super(props);

    this.mobileStickyHoverFix = new MobileStickyHoverFixPlugin('is-touching');

    // Polyfill for picture, srcset & img tags
    picturefill();

    // Check for browser support of loading WebP images, once completed document.supportsWebP will be a boolean indicating if the browser supports it
    checkWebPSupport();
  }

  componentDidMount() {
    this.mobileStickyHoverFix.mount();
  }

  componentWillUnmount() {
    this.mobileStickyHoverFix.unmount();
  }

  render() {
    return (
      <div>
        <Header smooth="easeOutQuad" duration={750} offset={-32} />

        <Home />

        <Container className="content">
          <About />

          <Skills />

          <Projects />

          <Experience />

          <Education />
        </Container>

        <Footer />
      </div>
    );
  }
}

export default App;
