import { ResponsiveImage } from './responsiveImage';

import React, { PureComponent } from 'react';
import { Row, Col, Jumbotron, Button, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Context created in order to dynamically load all responsive images
// Webpack unable to deduce what to package up when includes are dynamic
// Note: Anytime this context is used (e.g. ResponsiveImage), the path must be relative to this path, so ./profile-picture would actually be ./assets/images/profile-picture
const imagesContext = require.context('./assets/images', true, /^.*$/);

class Home extends PureComponent {
  /**
   * Calculates the sizes attribute of the responsive image containing the background image on the home screen
   *
   * The sizes attribute (of the img tag) is used to identify the correct background image size necessary as well as is sometimes used to sized the img tag itself
   *
   * Since the background image needs to fill the screen, it can be either width or height constrained depending on the screen size.
   *
   * Note: This function will only be called once the browser is loaded. If the device size changes after the initial load, as is possible on mobile devices with
   * changing from portrait to landscape, or if the user resizes their browser, then this will NOT be called. In theory, this will cause the browser to not download
   * a different image if the screen size changes. The only potential downside to this is that if the user starts at a small screen size and then scales the screen up,
   * the browser will not know to download a higher resolution image based on the sizes attribute and the image could look pixelated. However, simple testing on Chrome
   * did not show this to be the case or if it was, the lower resolution image was not noticeable.
   */
  calculateBackgroundSize() {
    // Aspect ratio of the screen, this is the desired size of the hero image
    const screenAspectRatio = document.documentElement.clientWidth / document.documentElement.clientHeight;

    // Aspect ratio of the image, known beforehand
    const imageAspectRatio = 1.5;

    // If the screen aspect ratio is less than the image aspect ratio, then we want to size based on the height
    // Otherwise, size based on the width
    // This will set the image size to be the maximum between width/height, such that the image covers the entire space
    return screenAspectRatio < imageAspectRatio ? `calc(${imageAspectRatio} * 100vh)` : '100vw';
  }

  render() {
    return (
      <Jumbotron fluid className="main-jumbotron" id="home">
        <ResponsiveImage
          context={imagesContext}
          src="./background-2-%ww.%e"
          exts={['jpg', 'webp']}
          widths={[1920, 1690, 1267, 960, 634, 480]}
          sizes={this.calculateBackgroundSize()}
          alt="Background image"
          className="background-image"
        />

        <Container fluid>
          <Row>
            <Col xs="12">
              <ResponsiveImage
                context={imagesContext}
                src="./profile-picture-%ww.%e"
                exts={['jpg', 'webp']}
                widths={[1024, 901, 727, 676, 543, 410, 338, 256]}
                sizes="250px"
                alt="Headshot"
                className="profile-picture rounded-circle"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <h1 className="name text-light">Addison Elliott</h1>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Button href="files/Addison Elliott Resume.pdf" color="primary" size="lg" className="mb-4">
                Download Resume
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <ul className="list-inline">
                <li className="list-inline-item mr-4">
                  <a href="https://www.github.com/addisonElliott">
                    <FontAwesomeIcon icon={['fab', 'github']} size="3x" />
                  </a>
                </li>
                <li className="list-inline-item mr-4">
                  <a href="https://www.linkedin.com/in/addison-elliott">
                    <FontAwesomeIcon icon={['fab', 'linkedin']} size="3x" />
                  </a>
                </li>
                <li className="list-inline-item mr-4">
                  <a href="mailto:addison.elliott@gmail.com">
                    <FontAwesomeIcon icon={['fa', 'envelope']} size="3x" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://stackoverflow.com/users/1804994/addison?tab=profile">
                    <FontAwesomeIcon icon={['fab', 'stack-overflow']} size="3x" />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    );
  }
}

export default Home;
