import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/fn/array/from';
import 'core-js/fn/promise';
import 'core-js/fn/object/assign';
import 'core-js/fn/string/includes';
import 'core-js/fn/array/includes';

import './polyfills/element-matches';

import React from 'react';
import ReactDOM from 'react-dom';

import './fontawesome';
import App from './app';
import * as serviceWorker from './serviceWorker';

// Replace root element in index.html with App tag
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//
serviceWorker.register();
