import React, { Component } from 'react';
import { Card, Row, Col, CardHeader, CardBody, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Counter for unique ID on number of each school
let schoolCount = 0;

class School extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false
    };

    this.tooltipID = 'education-tooltip-' + schoolCount++;

    // Bind components
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });

    clearTimeout(this._hideTimeout);

    if (this.state.tooltipOpen) {
      // Time to set a timer to have it turn off
      this._hideTimeout = setTimeout(this.toggle, 1000);
    }
  }

  clearShowTimeout() {
    clearTimeout(this._showTimeout);
    this._showTimeout = undefined;
  }

  render() {
    return (
      <Col xs="12" md="4" className="px-xs-0 text-lg-center">
        <h5 className="title">
          {this.props.degreeAbbr ? (
            <abbr title={this.props.degree} id={this.tooltipID}>
              {this.props.degreeAbbr}
            </abbr>
          ) : (
            this.props.degree
          )}{' '}
          {this.props.specialty}
          <Tooltip
            trigger="click"
            placement="bottom"
            isOpen={this.state.tooltipOpen}
            target={this.tooltipID}
            toggle={this.toggle}
          >
            {this.props.degree}
          </Tooltip>
        </h5>
        <h6 className="subtitle">{this.props.school}</h6>
        <p className="date">{this.props.date}</p>
        <p className="location">
          <FontAwesomeIcon icon="map-marker-alt" />
          {this.props.location}
        </p>
        <p className="gpa">
          <b>GPA: </b>
          {this.props.gpa}
        </p>
      </Col>
    );
  }
}

class Education extends Component {
  render() {
    return (
      <Row id="education" className="section">
        <Col>
          <Card className="minimalist">
            <CardHeader>Education</CardHeader>
            <CardBody>
              <Row>
                <School
                  degree="Master of Science"
                  degreeAbbr="MSc"
                  specialty="Electrical Engineering"
                  school="Southern Illinois University"
                  location="Edwardsville, IL"
                  date="August 2017 to May 2019"
                  gpa="4.0 out of 4.0"
                />

                <School
                  degree="Bachelor of Science"
                  degreeAbbr="BSc"
                  specialty="Electrical Engineering"
                  school="Southern Illinois University"
                  location="Edwardsville, IL"
                  date="August 2015 to May 2017"
                  gpa="4.0 out of 4.0"
                />

                <School
                  degree="Associate of Science"
                  degreeAbbr="ASc"
                  specialty="Engineering"
                  school="Lincoln Land Community College"
                  location="Springfield, IL"
                  date="August 2013 to May 2015"
                  gpa="4.0 out of 4.0"
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Education;
