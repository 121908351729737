import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';

import ScrollNavLink from './scrollNavLink';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    // Bind components
    this.onClick = this.onClick.bind(this);
    this.expand = this.expand.bind(this);
    this.collapse = this.collapse.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.onClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClick, false);
  }

  onClick(e) {
    // If we click somewhere and the collapsible navbar is being shown, then collapse it
    if (!e.target.matches('.navbar-toggler, .navbar-toggler-icon') && this.state.isOpen) {
      this.collapse();
    }
  }

  expand() {
    this.setState({
      isOpen: true
    });
  }

  collapse() {
    this.setState({
      isOpen: false
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <Navbar fixed="top" expand="md" className="navbar-custom">
        <NavbarBrand href="/">Addison Elliott</NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <ScrollNavLink to="home" smooth={this.props.smooth} duration={this.props.duration} href="#">
                Home
              </ScrollNavLink>
            </NavItem>
            <NavItem>
              {/* href attribute required to be able to focus with keyboard! Tested in Chrome & Edge */}
              <ScrollNavLink
                to="about"
                smooth={this.props.smooth}
                duration={this.props.duration}
                offset={this.props.offset}
                href="#"
              >
                About
              </ScrollNavLink>
            </NavItem>
            <NavItem>
              <ScrollNavLink
                to="skills"
                smooth={this.props.smooth}
                duration={this.props.duration}
                offset={this.props.offset}
                href="#"
              >
                Skills
              </ScrollNavLink>
            </NavItem>
            <NavItem>
              <ScrollNavLink
                to="projects"
                smooth={this.props.smooth}
                duration={this.props.duration}
                offset={this.props.offset}
                href="#"
              >
                Projects
              </ScrollNavLink>
            </NavItem>
            <NavItem>
              <ScrollNavLink
                to="experience"
                smooth={this.props.smooth}
                duration={this.props.duration}
                offset={this.props.offset}
                href="#"
              >
                Experience
              </ScrollNavLink>
            </NavItem>
            <NavItem>
              <ScrollNavLink
                to="education"
                smooth={this.props.smooth}
                duration={this.props.duration}
                offset={this.props.offset}
                href="#"
              >
                Education
              </ScrollNavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default Header;
