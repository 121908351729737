import React, { Component } from 'react';
import { Card, Row, Col, CardHeader, CardBody, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false
    };

    // Bind components
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });

    clearTimeout(this._hideTimeout);

    if (this.state.tooltipOpen) {
      // Time to set a timer to have it turn off
      this._hideTimeout = setTimeout(this.toggle, 1000);
    }
  }

  clearShowTimeout() {
    clearTimeout(this._showTimeout);
    this._showTimeout = undefined;
  }

  render() {
    return (
      <Row id="about" className="section">
        <Col>
          <Card className="minimalist">
            <CardHeader>About</CardHeader>
            <CardBody>
              <Row>
                <Col xs="12" md="6" className="px-xs-0">
                  <h2>Introduction</h2>
                  <p>
                    Hello, my name is Addison Elliott. I am a software engineer at BECS Technology. At BECS, I get to
                    work on a wide variety of projects such as embedded microprocessors running a Linux environment to
                    cloud-based applications that utilize NodeJS or Python to accomplish various tasks.
                  </p>
                  <p>
                    In my free time, I enjoy programming and contributing to open-source projects. I enjoy software and
                    the challenges that come along with it, whether it be for Android/iOS development, web development,
                    desktop applications, or microcontroller projects.
                  </p>
                </Col>
                <Col xs="12" md="6" className="basic-information px-xs-0">
                  <h2>Basic Information</h2>
                  <Row tag="dl">
                    <Col>
                      <Row>
                        <Col tag="dt" xs="12" sm="4" md="12" lg="3">
                          <FontAwesomeIcon icon="envelope" size="sm" />
                          <span>Email:</span>
                        </Col>
                        <Col tag="dd">
                          <a href="mailto:addison.elliott@gmail.com">addison.elliott@gmail.com</a>
                        </Col>
                      </Row>
                      <Row>
                        <Col tag="dt" xs="12" sm="4" md="12" lg="3">
                          <FontAwesomeIcon icon="map-marker-alt" size="sm" />
                          <span className="address">Address:</span>
                          <abbr className="address" title="Address" id="address-tooltip">
                            Addr:
                          </abbr>
                          <Tooltip
                            trigger="click"
                            placement="bottom"
                            isOpen={this.state.tooltipOpen}
                            target="address-tooltip"
                            toggle={this.toggle}
                          >
                            Address
                          </Tooltip>
                        </Col>
                        <Col tag="dd">St. Louis, MO</Col>
                      </Row>
                      <Row>
                        <Col tag="dt" xs="12" sm="4" md="12" lg="3">
                          <FontAwesomeIcon icon="phone" size="sm" flip="horizontal" />
                          <span>Phone:</span>
                        </Col>
                        <Col tag="dd">(309)-275-8402</Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default About;
