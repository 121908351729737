import React, { Component } from 'react';
import { Card, Row, Col, CardHeader, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NewTargetLink from './utils';

class Job extends Component {
  render() {
    return (
      <div className="item">
        <div className="work-place">
          <h3 className="place">{this.props.company}</h3>
          <div className="location">
            <FontAwesomeIcon icon="map-marker-alt" />
            {this.props.location}
          </div>
        </div>
        <div className="job-meta">
          <div className="title">{this.props.title}</div>
          <div className="time">{this.props.date}</div>
        </div>
        <div className="location-stacked">
          <FontAwesomeIcon icon="map-marker-alt" />
          {this.props.location}
        </div>
        <div className="job-desc">{this.props.children}</div>
      </div>
    );
  }
}

class Experience extends Component {
  render() {
    return (
      <Row id="experience" className="section">
        <Col>
          <Card className="minimalist">
            <CardHeader>Work Experience</CardHeader>
            <CardBody>
              <Row>
                <Col xs="12">
                  <div className="timeline">
                    <Job
                      company="BECS Technology"
                      location="St. Louis, MO"
                      title="Software Engineer"
                      date="May 2019 to Present"
                    >
                      <p>
                        Developed software in both the aquatic and agricultural divisions of the company. Projects
                        included embedded microprocessor software in addition to backend infrastructure for cloud
                        computing.
                      </p>
                      <p>
                        Company website: <NewTargetLink href="http://www.becs.com">BECS Technology</NewTargetLink>
                      </p>
                      <span className="font-weight-bold">Accomplishments:</span>
                      <ul>
                        <li>
                          Ported and redesigned 20-year old C code from Z80 processor to embedded Linux environment
                          utilizing C++17 for aquatics chemistry controllers
                        </li>
                        <li>
                          Implemented REST API for public access to real-time data coming from aquatics chemistry
                          controller using C++ and Qt
                        </li>
                        <li>
                          Implemented REST API for real-time access to data from our agricultural products using NodeJS
                        </li>
                        <li>
                          Developed backend application in Python to analyze chemistry controllers connected to the
                          cloud and predict various faults to warn users ahead of time
                        </li>
                      </ul>
                    </Job>

                    <Job
                      company="Southern Illinois University"
                      location="Edwardsville, IL"
                      title="Research Assistant"
                      date="August 2017 to May 2019"
                    >
                      <p>
                        I worked in the biomedical engineering research lab at SIUE under{' '}
                        <NewTargetLink href="https://www.siue.edu/engineering/ece/jokling_ee.shtml">
                          Dr. Jon Klingensmith
                        </NewTargetLink>
                        . My research was focused on the development and validation of fat segmentation algorithms in
                        both Dixon MRI and ultrasound images. Throughout my time in the lab, I developed skills using
                        Python, Anaconda, ITK, VTK, OpenCV and other image processing libraries.
                      </p>
                      <span className="font-weight-bold">Accomplishments:</span>
                      <ul>
                        <li>
                          PATS software developed as a generic 3D medical imaging tool that future researchers can
                          develop modules within
                        </li>
                        <li>
                          Dixon MRI fat segmentation algorithm developed to segment fat depots from three types of scans
                        </li>
                        <li>
                          IQ to B-mode conversion algorithm created to convert raw IQ data to B-mode images in
                          ultrasound
                        </li>
                        <li>
                          Used echocardiograms to segment endocardial boundary of the heart using dynamic programming
                        </li>
                        <li>Developed numerous Python libraries to aid in completion of projects</li>
                        <li>Git version control &amp; GitHub was used for all projects in lab</li>
                        <li>Supervised 5 undergraduate students on completion of their projects in PATS software</li>
                        <li>Published 2 academic articles and attended BMES 2018 conference</li>
                      </ul>
                    </Job>

                    <Job
                      company="Social Local Mobile"
                      location="Edwardsville, IL"
                      title="Android/iOS Developer"
                      date="August 2017 to May 2019"
                    >
                      <p>
                        Social Local Mobile is a startup company with their primary project being
                        <span className="font-weight-bold font-italic"> Custody Transfer Log</span>. I was hired as a
                        freelancer over two summers to design and develop their product in Android &amp; iOS. The app
                        can be downloaded on Google Play and the Apple Store.
                      </p>
                      <span className="font-weight-bold">Accomplishments:</span>
                      <ul>
                        <li>Design and developed Android app using Android Studio</li>
                        <li>Design and developed iOS app using XCode &amp; Swift 4</li>
                        <li>
                          Developed database backend for user authentication and storage using MongoDB, Node.js &amp;
                          Parse Server
                        </li>
                        <li>Implemented automatic PDF generation using PHP on webserver</li>
                      </ul>
                    </Job>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Experience;
