// Import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// Import icons
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faBirthdayCake,
  faEnvelope,
  faPhone,
  faCheck,
  faMapMarkerAlt,
  faGlobeAmericas
} from '@fortawesome/free-solid-svg-icons';

// Add icons to library
library.add(fab, faBirthdayCake, faEnvelope, faPhone, faCheck, faMapMarkerAlt, faGlobeAmericas);
